import "./Organizations.css";
import imars from "../../images/imars.jpg";
import pfsrm from "../../images/pfsrm.jpg";
import ssrm from "../../images/ssrm.png";

export function Organizations() {
  return (
    <div className="section" id="org-section">
      <div id="organizations">
        <a href="https://imars.pl" target="_blank" rel="noopener noreferrer">
          <img
            src={imars}
            alt="Instytut Monitoringu i Analiz Rynku Nieruchomości Silesia - logo"
            title="Logo IMARS"
          />
        </a>
        <a href="https://pfsrm.pl" target="_blank" rel="noopener noreferrer">
          <img
            src={pfsrm}
            alt="Polska Federacja Stowarzyszeń Rzeczoznawców Majątkowych - logo"
            title="Logo PFSRM"
          />
        </a>
        <a
          href="https://www.srm.com.pl/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={ssrm}
            alt="Śląskie Stowarzyszenie Rzeczoznawców Majątkowych - logo"
            title="Logo SSRM"
          />
        </a>
      </div>
    </div>
  );
}
