import { PhoneIcon } from "../../Icons";
import "./Button.css";

export function OutlinedButton(props) {
  return (
    <a className="button_a" href={props.contactTo}>
      <button className="button outlined">
        <PhoneIcon width="30px" height="30px" />
        <span>{props.text}</span>
      </button>
    </a>
  );
}
