import "./Footer.css";

export function Footer() {
  return (
    <footer>
      <div id="kontakt">
        <div className="section">
          <h2>Kontakt</h2>
          <div className="contact">
            <div className="contact-area">
              <p>
                <strong>Dane Firmy</strong> <br />
                Karol Zawada Wycena Nieruchomości
              </p>

              <p>
                <strong>Adres:</strong> <br />
                41-216 Sosnowiec <br />
                ul. Wileńska 12A
              </p>

              <p>
                <strong>NIP:</strong> 6443176178 <br />
                <strong>REGON:</strong> 527139388
              </p>

              <p>
                <strong>Alior Bank S.A.</strong> <br />
                58 2490 0005 0000 4530 0018 7121
              </p>
            </div>
            <div className="contact-area contact-area-right">
              <p>
                <strong>Telefon:</strong>{" "}
                <a href="tel:662-098-627">662 098 627</a>
              </p>

              <p>
                <strong>E-mail:</strong>{" "}
                <a href="mailto:kontakt@wycena-nieruchomosci.pl">
                  kontakt@wycena-nieruchomosci.pl
                </a>
              </p>
              <p>
                <a href="/polityka-prywatnosci">Polityka prywatności</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="bottom-footer">
        <h4>© Karol Zawada Wycena Nieruchomości</h4>
        <span>
          <a
            href="https://github.com/KamilDonda"
            target="_blank"
            rel="noopener noreferrer"
          >
            Wykonanie - Kamil Donda
          </a>
        </span>
      </div>
    </footer>
  );
}
