import "./Window.css";

export function Window({blueBar=true, title, list, description}) {
  return (
    <div className="window">
      <h3 className={blueBar ? "blue-bar" : "no-bar"}>{title}</h3>
      {list ? <ul>{list}</ul> : <p>{description}</p>}
    </div>
  );
}
