import React from "react";
import { Navbar } from "../components/Navbar/Navbar";
import { Photo } from "../components/Photo/Photo";
import { Services } from "../sections/Services/Services";
import { ValuationPurposes } from "../sections/ValuationPurposes/ValuationPurposes";
import { Steps } from "../sections/Steps/Steps";
import { Documents } from "../sections/Documents/Documents";
import { PriceList } from "../sections/PriceList/PriceList";
import { About } from "../sections/About/About";
import { Organizations } from "../sections/Organizations/Organizations";
import { Footer } from "../sections/Footer/Footer";

export function HomePage() {
  return (
    <>
      <Navbar />
      <Photo />
      <Services />
      <ValuationPurposes />
      <PriceList />
      <Steps />
      <Documents />
      <About />
      <Organizations />
      <Footer />
    </>
  );
}
