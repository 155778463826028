import React from "react";
import { Navbar } from "../components/Navbar/Navbar";
import { Policy } from "../sections/Policy/Policy";
import { Footer } from "../sections/Footer/Footer";

export function PolicyPage() {
  return (
    <>
      <Navbar />
      <Policy/>
      <Footer />
    </>
  );
}
