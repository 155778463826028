import "./Policy.css";

export function Policy() {
  return (
    <div className="section" id="policy">
      <h3>Polityka Prywatności</h3>
      <p>
        Informujemy, że od&nbsp;dnia 25 maja 2018 r. w&nbsp;zakresie
        przetwarzania danych osobowych obowiązuje Rozporządzenie Parlamentu
        Europejskiego i&nbsp;Rady Unii Europejskiej 2016/679 z&nbsp;27 kwietnia
        2016 r. w&nbsp;sprawie ochrony osób fizycznych w&nbsp;związku
        z&nbsp;przetwarzaniem danych osobowych i&nbsp;w&nbsp;sprawie swobodnego
        przepływu takich danych oraz&nbsp;uchylenia dyrektywy 95/46/WE („RODO”).
      </p>
      <p>
        Poniższe informacje dotyczące zasad przetwarzania Twoich danych
        osobowych, przekazujemy Ci&nbsp;w&nbsp;wykonaniu obowiązku nałożonego
        przez przepis art. 13 RODO.
      </p>

      <ol>
        <li>
          {/* 1 */}
          Polityka Prywatności określa zasady prywatności i&nbsp;przetwarzania
          danych osobowych użytkowników serwisu{" "}
          <strong>www.wycena-nieruchomosci.pl</strong> prowadzonego w&nbsp;sieci
          Internet (dalej jako „<strong>Serwis</strong>”).
        </li>
        <li>
          {/* 2 */}
          <strong>Administratorem</strong> danych osobowych Użytkowników jest
          Karol Zawada prowadzący działalność gospodarczą pod firmą Karol Zawada
          Wycena Nieruchomości, ul. Wileńska 12a, 41-216 Sosnowiec. Kontakt
          z&nbsp;Administratorem e-mail:{" "}
          <a href="mailto:kontakt@wycena-nieruchomosci.pl">
            kontakt@wycena-nieruchomosci.pl
          </a>
          .
        </li>
        <li>
          {/* 3 */}
          Dane Użytkownika, wprowadzane do&nbsp;Serwisu, stanowią własność
          Użytkownika.
        </li>
        <li>
          {/* 4 */}
          W&nbsp;zakresie danych zwykłych np. takich jak: imię, nazwisko,
          telefon kontaktowy, e-mail dane Użytkownika będą przetwarzane
          wyłącznie w&nbsp;celu umożliwienia korzystania z&nbsp;funkcjonalności
          Serwisu, w&nbsp;celu świadczenia usług, odpowiedzi na&nbsp;zadane
          przez Użytkownika pytania, złożenia oferty handlowej, zawarcia umowy,
          realizacji umowy, a&nbsp;także do&nbsp;dokonywania rozliczeń
          za&nbsp;zakupione usługi lub towary, do&nbsp;kontaktu, jak również
          w&nbsp;celu ewentualnego ustalenia, dochodzenia lub obrony przed
          roszczeniami, w&nbsp;celach dowodowych, analitycznych, archiwalnych,
          prowadzenia księgowości i&nbsp;rachunkowości. Dane w&nbsp;tych celach
          przetwarzane będą na&nbsp;podstawie art. 6 ust. 1 lit. b), c)
          i&nbsp;f) RODO, a&nbsp;zatem, gdy:
        </li>
        <ul>
          <li>
            {/* 4a */}
            przetwarzanie jest niezbędne do&nbsp;wykonania umowy, której stroną
            jest osoba, której dane dotyczą, lub&nbsp;do&nbsp;podjęcia działań
            na&nbsp;żądanie osoby, której dane dotyczą, przed zawarciem umowy,
          </li>
          <li>
            {/* 4b */}
            przetwarzanie jest niezbędne do&nbsp;wypełnienia obowiązku prawnego
            ciążącego na&nbsp;Administratorze,
          </li>
          <li>
            {/* 4c */}
            przetwarzanie jest niezbędne do&nbsp;celów wynikających
            z&nbsp;prawnie uzasadnionych interesów realizowanych przez
            Administratora lub&nbsp;przez stronę trzecią, z&nbsp;wyjątkiem
            sytuacji, w&nbsp;których nadrzędny charakter wobec tych interesów
            mają interesy lub podstawowe prawa i&nbsp;wolności osoby, której
            dane dotyczą, wymagające ochrony danych osobowych.
          </li>
        </ul>
        <li>Użytkownik ma prawo do:</li>
        {/* 1 */}
        <ul>
          <li>dostępu do&nbsp;swoich danych oraz otrzymania ich kopii,</li>
          {/* 5a */}
          <li>sprostowania (poprawiania) swoich danych,</li>
          {/* 5b */}
          <li>
            {/* 5c */}
            usunięcia danych – jeżeli zdaniem Użytkownika nie ma podstaw
            do&nbsp;tego, by&nbsp;Administrator przetwarzał jego dane,
          </li>
          <li>
            {/* 5d */}
            ograniczenia przetwarzania danych – jeżeli zdaniem Użytkownika
            Administrator posiada nieprawidłowe dane na&nbsp;jego temat
            lub&nbsp;przetwarza je&nbsp;bezpodstawnie; lub&nbsp;Użytkownik nie
            wyraża zgody na&nbsp;usunięcie danych ze&nbsp;względu
            na&nbsp;potrzebę ustalenia, dochodzenia lub obrony roszczeń; lub
            na&nbsp;czas wniesionego przez Użytkownika sprzeciwu względem
            przetwarzania danych,
          </li>
          <li>
            {/* 5e */}
            wniesienia sprzeciwu wobec przetwarzania danych na&nbsp;podstawie
            prawnie uzasadnionego interesu Administratora, a&nbsp;także, gdy
            przetwarzanie jest niezbędne Administratorowi do&nbsp;wykonania
            zadania realizowanego w&nbsp;interesie publicznym lub
            do&nbsp;sprawowania powierzonej nam władzy publicznej,
          </li>
          <li>
            {/* 5f */}
            przenoszenia danych – Użytkownik prawo otrzymać
            od&nbsp;Administratora w&nbsp;ustrukturyzowanym, powszechnie
            używanym formacie nadającym się do&nbsp;odczytu maszynowego dane
            swoje dane osobowe, które dostarczył Administratorowi
            na&nbsp;podstawie umowy lub zgody, Użytkownik może też zlecić
            Administratorowi przesłanie tych danych bezpośrednio innemu
            podmiotowi,
          </li>
          <li>
            {/* 5g */}
            wniesienia skargi do&nbsp;organu nadzorczego – jeżeli Użytkownik
            uważa, że Administrator przetwarza jego dane niezgodnie
            z&nbsp;prawem, może złożyć w&nbsp;tej sprawie skargę do&nbsp;Prezesa
            Urzędu Ochrony Danych Osobowych lub innego właściwego organu
            nadzorczego,
          </li>
          <li>
            {/* 5h */}
            prawo do&nbsp;cofnięcia zgody na&nbsp;przetwarzanie danych osobowych
            – w&nbsp;każdej chwili Użytkownik ma&nbsp;prawo cofnąć zgodę
            na&nbsp;przetwarzanie tych danych osobowych, które Administrator
            przetwarza na&nbsp;podstawie jego zgody; cofnięcie zgody
            nie&nbsp;będzie wpływać na&nbsp;zgodność z&nbsp;prawem
            przetwarzania, którego dokonano na&nbsp;podstawie zgody przed jej
            wycofaniem.
          </li>
        </ul>
        <li>
          {/* 6 */}
          Użytkownik udostępnia wszelkie dane dobrowolnie i&nbsp;świadomie.
        </li>
        <li>
          {/* 7 */}
          Dostęp do&nbsp;danych osobowych Użytkownika mogą mieć następujące
          kategorie podmiotów:
        </li>
        <ul>
          <li>
            {/* 7a */}
            osoby upoważnione przez Administratora, jak&nbsp;upoważnieni
            pracownicy, współpracownicy Administratora,
          </li>
          <li>
            {/* 7b */}
            dostawcy usług zaopatrujących Administratora w&nbsp;rozwiązania
            techniczne oraz organizacyjne, umożliwiające świadczenie&nbsp;usług
            funkcjonalności (w&nbsp;szczególności podmioty świadczące
            w&nbsp;usługi kurierskie i&nbsp;pocztowe, usługi IT, marketingowe,
            dostawcy usług prawnych, księgowych i&nbsp;doradczych oraz ich
            upoważnieni pracownicy i&nbsp;współpracownicy),
          </li>
        </ul>
        <li>
          {/* 9 */}
          Administrator może zbierać dane statystyczne dotyczące popularności
          i&nbsp; zastosowania poszczególnych usług oferowanych przez Serwis,
          dzieląc się nimi z&nbsp;innymi podmiotami. Dane te będą udostępniane
          tylko anonimowo i&nbsp;grupowo, bez możliwości wyodrębnienia
          na&nbsp;ich podstawie danych osobowych Użytkowników. Zbierane dane
          statystyczne Administrator może wykorzystywać także w&nbsp;celach
          marketingowych, informacyjnych, poprawy funkcjonalności Serwisu oraz
          statystycznych.
        </li>
        <li>
          {/* 10 */}
          Dane osobowe przekazane przez Użytkownika będą przetwarzane
          w&nbsp;zakresie i&nbsp;przez okres niezbędny do&nbsp;realizacji celów,
          dla których zostały zebrane. Okres ten nie jest możliwy
          do&nbsp;jednoznacznego określenia i&nbsp;zależy od&nbsp;decyzji
          dotyczących korzystania z&nbsp;usług Administratora, w związku
          z&nbsp;którymi dane zostały pozyskane. w&nbsp;związku z&nbsp;umowami,
          które wygasły lub&nbsp; zostały rozwiązane, czas przetwarzania danych
          osobowych przez Administratora determinują okresy przedawnienia
          roszczeń z Kodeksu Cywilnego oraz obowiązki z&nbsp;zakresu prawa
          podatkowego i&nbsp; rachunkowości, a&nbsp;zatem nie będziemy
          przetwarzać danych dłużej niż 6 lat od&nbsp;zakończenia trwania umowy.
          Dane osobowe przetwarzane w&nbsp;celach objętych oświadczeniem zgody
          będą przetwarzane w&nbsp;tych celach do&nbsp;czasu odwołania zgody.
        </li>
        <li>
          {/* 11 */}
          Serwis zbiera w&nbsp;sposób automatyczny wyłącznie informacje zawarte
          w plikach cookies, o&nbsp;których mowa poniżej.
        </li>
        <li>
          {/* 12 */}
          W&nbsp;wyniku korzystania z&nbsp;Serwisu, w&nbsp;urządzeniach
          użytkownika mogą być przechowywane pliki tekstowe (tzw. ciasteczka –
          cookies), których celem jest ułatwienie użytkownikowi dostępu
          do&nbsp;Serwisu i&nbsp;korzystania z niego, a&nbsp;także badanie
          preferencji i&nbsp;zachowania Użytkownika, aby umożliwić dostarczenie
          spersonalizowanych treści w&nbsp;Serwisie oraz polepszyć
          funkcjonowanie Serwisu. Dane są zbierane przez pliki cookies także
          w&nbsp;celach statystycznych, analitycznych i&nbsp;celem wsparcia
          promocji Serwisu w&nbsp;sieci Internet.
        </li>
        <li>
          {/* 13 */}
          Użytkownik może wyłączyć umieszczanie i&nbsp;przechowywanie plików
          cookies z&nbsp;poziomu używanej przez siebie przeglądarki
          internetowej. Sposób wyłączenia plików cookies zależy
          od&nbsp;stosowanej przez użytkownika przeglądarki internetowej.
          Szczegółowe informacje o&nbsp;możliwości i&nbsp;sposobach obsługi
          plików cookies dostępne są w&nbsp;ustawieniach oprogramowania
          (przeglądarki internetowej).
        </li>
        <li>
          {/* 14 */}
          Informacje na&nbsp;temat zarządzania plikami cookies są dostępne
          w&nbsp;sekcji Pomoc poszczególnych przeglądarek internetowych.
        </li>
        <li>
          {/* 15 */}
          Gromadzone są pliki cookies w&nbsp;dwóch rodzajach – jako pliki
          „sesyjne” oraz pliki „stałe”. Pierwsze z&nbsp;nich są&nbsp;plikami
          tymczasowymi, które pozostają na&nbsp;urządzeniu użytkownika, aż&nbsp;
          do&nbsp;wylogowania ze strony internetowej lub wyłączenia
          oprogramowania (przeglądarki internetowej). „Stałe” pliki pozostają
          na&nbsp; urządzeniu Użytkownika przez czas określony
          w&nbsp;parametrach plików „cookies” albo do&nbsp;momentu ich ręcznego
          usunięcia przez użytkownika.
        </li>
        <li>
          {/* 17 */}
          Administrator zastrzega, że ograniczenie stosowania plików cookies
          może wpłynąć na&nbsp;niektóre funkcjonalności dostępne
          w&nbsp;Serwisie, a&nbsp;w skrajnych przypadkach może uniemożliwić
          korzystanie z&nbsp;Serwisu.
        </li>
      </ol>
    </div>
  );
}
