import { Button } from "../Button/Button";
import { OutlinedButton } from "../Button/OutlinedButton";
import "./Banner.css";

export function Banner() {
  return (
    <div className="banner">
      <div className="banner_background">
        <h3 className="banner_h">Karol Zawada</h3>
        <h1 className="banner_h">Wycena Nieruchomości</h1>
        <h2 className="banner_h">Górny Śląsk i okolice</h2>
        <div className="banner_row">
          <Button
            text="kontakt@wycena-nieruchomosci.pl"
            contactTo="mailto:kontakt@wycena-nieruchomosci.pl"
          />
          <OutlinedButton text="662 098 627" contactTo="tel:662-098-627" />
        </div>
      </div>
    </div>
  );
}
