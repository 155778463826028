import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import "./ValuationPurposes.css";

export function ValuationPurposes() {
  const purposes = [
    "na potrzeby sprzedaży nieruchomości,",
    "ustanowienie służebności przesyłu oraz wynagrodzenie za\u00A0bezumowne korzystanie z nieruchomości,",
    "wycena nieruchomości na potrzeby postępowania egzekucyjnego, upadłościowego, padłości konsumenckiej,",
    "zabezpieczenie kredytów hipotecznych,",
    "dla celów ubezpieczeniowych,",
    "sprawozdania finansowe,",
    "skarbowo – podatkowe,",
    "ustalenie wartości początkowej środków trwałych jako podstawy do naliczania amortyzacji,",
    "skutki finansowe uchwalenia lub zmiany planów miejscowych,",
    "wkłady niepieniężne do spółek kapitałowych (tzw. aportów),",
    "pozostałe zgodnie z potrzebami zamawiającego.",
  ];

  const purposesItems = purposes.map((item, index) => <li key={`purpose${index}`}>{item}</li>);

  return (
    <>
      <SectionHeader text="Cel wyceny" />
      <div id="valuation-purposes">
        <div className="section">
          <ul>{purposesItems}</ul>
        </div>
      </div>
    </>
  );
}
