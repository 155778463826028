import React, { useState } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Hamburger } from "../Button/Hamburger";

export function MobileNavbar({ sections }) {
  function sectionItems() {
    return sections.map((section) => (
      <li key={`${section.title}-mobile`}>
        <a href={section.path} onClick={toggleDrawer(false)}>{section.title}</a>
      </li>
    ));
  }

  const [open, setOpen] = useState(false);

  const toggleDrawer = (isOpen) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(isOpen);
  };

  return (
    <div id="mobile-navbar">
      <React.Fragment>
        <Hamburger fun={toggleDrawer(true)} />
        <SwipeableDrawer
          id="swipeable-drawer"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <div id="mobile-drawer">{sectionItems()}</div>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
