import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import "./About.css";

export function About() {
  return (
    <div id="about">
      <div id="rzeczoznawca" className="shifted-anchor"></div>
      <div>
        <SectionHeader text="Rzeczoznawca majątkowy" />
        <div className="section">
          <h4>Karol Zawada</h4>
          <p>
            rzeczoznawca majątkowy
            <br />
            uprawnienia zawodowe nr&nbsp;8135 w&nbsp;zakresie szacowania
            nieruchomości, <br />
            nadane przez Ministra Rozwoju, Pracy&nbsp;i&nbsp;Technologii
          </p>
          <ul>
            <li>
              Czynności zawodowe wykonuję zgodnie z&nbsp;zasadami wynikającymi
              z&nbsp; przepisów prawa, ze&nbsp;szczególną starannością właściwą
              dla&nbsp;zawodowego charakteru tych czynności oraz z&nbsp;zasadami
              etyki zawodowej, kierując się zasadą bezstronności w&nbsp;wycenie
              nieruchomości.
            </li>
            <li>
            Jestem na liście R.M., którzy zdali egzamin „Wycena nieruchomości dla potrzeb zabezpieczenia wierzytelności bankowych” organizowany przez Związek Banków Polskich.
            </li>
            <li>
              Posiadam obowiązkowe ubezpieczenie odpowiedzialności cywilnej
              za&nbsp; szkody wyrządzone w&nbsp;związku z&nbsp;wykonywaniem
              umowy.
            </li>
          </ul>
          <h4>Jestem zrzeszony w:</h4>
        </div>
      </div>
    </div>
  );
}
