import { Navbar } from "../components/Navbar/Navbar";
import { Footer } from "../sections/Footer/Footer";
import { NotFound } from "../sections/NotFound/NotFound";

export function NotFoundPage() {
  return (
    <>
      <Navbar />
      <NotFound />
      <Footer />
    </>
  );
}
