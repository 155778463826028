import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import "./PriceList.css";

export function PriceList() {
  const priceList = [
    { service: "Lokal mieszkalny", price: "od 700 zł" },
    { service: "Dom jednorodzinny", price: "od 1000 zł" },
    { service: "Kamienica", price: "od 2000 zł" },
    { service: "Działka niezabudowana", price: "od 700 zł" },
    { service: "Nieruchomości komercyjne", price: "cena do uzgodnienia" },
    { service: "Opracowania i ekspertyzy", price: "cena do uzgodnienia" },
    { service: "Konsultacje", price: "od 200 zł" },
  ];

  const table = priceList.map((tr, index) => (
    <tr key={`table${index}`}>
      <td>{tr.service}</td>
      <td>{tr.price}</td>
    </tr>
  ));

  return (
    <>
      <div id="cennik" className="shifted-anchor"></div>
      <div id="price-list">
        <SectionHeader text="Cennik" />
        <div className="section">
          <p>
            Koszt wykonania usługi określającej wartość nieruchomości jest
            ustalany indywidualnie dla każdego zlecenia. Zależy od wielu
            czynników tj.&nbsp;rodzaju wycenianej nieruchomości, celu i zakresu
            wyceny, stopnia jej złożoności, nakładu pracy. Przy wycenach
            typowych obiektów, w&nbsp;zależności od pracochłonności sporządzanej
            opinii, termin wykonania operatu waha się od kilku dni do 2 tygodni.
          </p>
          <table id="pricing">
            <tbody>
              <tr>
                <th>Rodzaj nieruchomości / usługi</th>
                <th>Cena</th>
              </tr>
              {table}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
