import { MailIcon } from "../../Icons";
import "./Button.css";

export function Button(props) {
  return (
    <a className="button_a" href={props.contactTo}>
      <button className="button">
        <MailIcon width="30px" height="30px" />
        <span>{props.text}</span>
      </button>
    </a>
  );
}
