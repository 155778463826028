import "./Banner.css";

export function NotFoundBanner() {
  return (
    <a className="not-found-banner" href="/">
      <h2>404</h2>
      <h4>Niestety, ta strona nie&nbsp;istnieje :&#8239;(</h4>
    </a>
  );
}
