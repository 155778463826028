import { HamburgerIcon } from "../../Icons";
import "./Button.css";

export function Hamburger({ fun }) {
  return (
    <button className="hamburger" onClick={fun}>
      <HamburgerIcon width="40px" height="40px" />
    </button>
  );
}
