import React, { useEffect, useState } from "react";
import { MailIcon, PhoneIcon, IconLocation } from "../../Icons";
import logo from "../../images/karol-zawada-wycena-nieruchomosci-logo.svg";
import "./Navbar.css";
import { MobileNavbar } from "./MobileNavbar";

export function Navbar() {
  const sections = [
    { title: "Usługi", path: "/#uslugi" },
    { title: "Cennik", path: "/#cennik" },
    { title: "Wycena", path: "/#krok-po-kroku" },
    { title: "Dokumenty", path: "/#dokumenty" },
    { title: "Rzeczoznawca", path: "/#rzeczoznawca" },
    { title: "Kontakt", path: "/#kontakt" },
  ];

  function sectionItems() {
    return sections.map((section) => (
      <li key={section.title}>
        <a className={`${show && "expanded"}`} href={section.path}>
          {section.title}
        </a>
      </li>
    ));
  }

  const [show, setShow] = useState(true);
  const controlNavbar = () => {
    if (window.scrollY > 200) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-top">
          <a href="tel:662-098-627">
            <PhoneIcon width="30px" height="30px" />
            <span>+48 662-098-627</span>
          </a>
          <a href="mailto:kontakt@wycena-nieruchomosci.pl">
            <MailIcon width="30px" height="30px" />
            <span>kontakt@wycena-nieruchomosci.pl</span>
          </a>
          <a
            href="https://goo.gl/maps/EZmTSqerFqdJY4XV9"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconLocation width="30px" height="30px" />
            <span>ul. Wileńska 12a, 41-216 Sosnowiec</span>
          </a>
        </div>
        <div className={`navbar-bottom ${show && "navbar-bottom-visible"}`}>
          <a className={`logo ${show && "logo-visible"}`} href="/">
            <img
              src={logo}
              alt="Karol Zawada - rzeczoznawca majątkowy zajmujący się wyceną nieruchomości na Górnym Śląsku i w okolicy"
              title="Rzeczoznawca majątkowy - Karol Zawada"
            ></img>
          </a>
          <ul>{sectionItems()}</ul>
          <MobileNavbar sections={sections}/>
        </div>
      </nav>
      <div id="nav"></div>
    </>
  );
}
