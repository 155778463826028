import { Window } from "../../components/Window/Window";
import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import "./Documents.css";

export function Documents() {
  const documentsLeft = [
    {
      title: "Nieruchomość gruntowa niezabudowana",
      list: [
        "numer księgi wieczystej,",
        "wypis z\u00A0rejestru gruntów,",
        "mapa ewidencyjna,",
        "mapa zasadnicza,",
        "wypis z\u00A0miejscowego planu zagospodarowania przestrzennego lub\u00A0decyzja o\u00A0warunkach zabudowy,",
        "odpis z\u00A0księgi wieczystej,",
        "posiadane umowy np.\u00A0najmu, dzierżawy, darowizny,",
        "kopie decyzji np.\u00A0o\u00A0warunkach zabudowy,",
        "inne dokumenty opisujące stan prawny nieruchomości.",
      ],
    },
    {
      title: "Lokal mieszkalny",
      list: [
        "numer księgi wieczystej,",
        "zaświadczenie ze\u00A0Spółdzielni Mieszkaniowej (zawierające m.in. informacje, komu przysługuje spółdzielcze własnościowe prawo do\u00A0lokalu, oraz\u00A0powierzchnię użytkową, położenie na\u00A0piętrze i\u00A0układ funkcjonalny lokalu),",
        "posiadane umowy np. najmu, dzierżawy, darowizny,",
        "inne dokumenty opisujące stan prawny nieruchomości.",
      ],
    },
  ];

  const documentsRight = [
    {
      title: "Nieruchomość gruntowa zabudowana",
      list: [
        "numer księgi wieczystej,",
        "wypis z\u00A0rejestru gruntów,",
        "wypis z\u00A0kartoteki budynku,",
        "mapa ewidencyjna,",
        "mapa zasadnicza,",
        "wypis z\u00A0miejscowego planu zagospodarowania przestrzennego lub\u00A0decyzja o\u00A0warunkach zabudowy,",
        "odpis z\u00A0księgi wieczystej,",
        "posiadane umowy np. najmu, dzierżawy, darowizny,",
        "kopie decyzji np. o\u00A0warunkach zabudowy,",
        "dokumentacja architektoniczna budynku,",
        "decyzja o\u00A0oddaniu budynku do\u00A0użytkowania lub\u00A0zgłoszenia zakończenia budowy,",
        "inne dokumenty opisujące stan prawny nieruchomości.",
      ],
    },

    {
      title: "Lokal komercyjny",
      list: ["lista dokumentów ustalana indywidualnie."],
    },
  ];

  function documentsMap(list) {
    return list.map((window, windowIndex) => (
      <Window
        key={`docWindow${windowIndex}`}
        title={window.title}
        list={window.list.map((document, index) => (
          <li key={`doc${index}`}>{document}</li>
        ))}
      />
    ));
  }

  return (
    <>
      <div id="dokumenty" className="shifted-anchor"></div>
      <SectionHeader text="Dokumenty" />
      <div className="section">
        <div className="documents">
          <div className="doc-column">{documentsMap(documentsLeft)}</div>
          <div className="doc-column">{documentsMap(documentsRight)}</div>
        </div>
      </div>
    </>
  );
}
