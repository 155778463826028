import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import { Window } from "../../components/Window/Window";
import { Number } from "../../components/Number/Number";
import "./Steps.css";

export function Steps() {
  const steps = [
    {
      title: "Zamówienie wyceny",
      description:
        "Omówienie szczegółów współpracy. Pozyskanie wstępnych informacji o\u00A0nieruchomości, zakresie i\u00A0celu wyceny, koniecznych do\u00A0pozyskania dokumentów, określenie wstępnego kosztu realizacji operatu szacunkowego, ustalenie terminu oględzin (po\u00A0przesłaniu przez zleceniodawcę niezbędnych do\u00A0wyceny dokumentów).",
    },
    {
      title: "Oględziny nieruchomości",
      description:
        "Sporządzenie dokumentacji fotograficznej nieruchomości, przeprowadzenie wywiadu terenowego, sporządzenie protokołu z\u00A0oględzin. Podpisanie umowy na\u00A0wykonanie zlecenia.",
    },
    {
      title: "Sporządzenie opinii o\u00A0wartości nieruchomości",
      description:
        "Operat szacunkowy zawiera w\u00A0ogólności: przedmiot, zakres i\u00A0cel wyceny, podstawy formalne wyceny i\u00A0źródła danych o\u00A0nieruchomości, stan i\u00A0przeznaczenie nieruchomości, analizę i\u00A0charakterystykę rynku nieruchomości w\u00A0zakresie dotyczącym celu i\u00A0sposobu wyceny, podejście, metodę, technikę szacowania nieruchomości, obliczenia, oszacowaną wartość wraz z\u00A0uzasadnieniem wyniku. Klauzule oraz dokumenty wykorzystane przy sporządzaniu operatu szacunkowego.",
    },
    {
      title: "Przekazanie opracowania zleceniodawcy",
      description:
        "Operat szacunkowy przekazuję osobiście lub\u00A0pocztą w\u00A0terminie dogodnym dla\u00A0Zleceniodawcy.",
    },
  ];

  const stepItems = steps.map((item, index) => (
    <div className="step-row" key={`step${index}`}>
      {index % 2 === 0 ? (
        <Window title={item.title} description={item.description} />
      ) : (
        <div className="empty"></div>
      )}
      <Number number={index + 1} />
      {index % 2 === 0 ? (
        <div className="empty"></div>
      ) : (
        <Window title={item.title} description={item.description} />
      )}
    </div>
  ));

  return (
    <>
      <div id="krok-po-kroku" className="shifted-anchor"></div>
      <div id="steps">
        <SectionHeader text="Wycena (krok&nbsp;po&nbsp;kroku)" />
        <div className="section">{stepItems}</div>
      </div>
    </>
  );
}
