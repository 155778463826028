import { Window } from "../../components/Window/Window";
import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import "./Services.css";

export function Services() {
  const services = [
    {
      title: "Wycena nieruchomości",
      list: [
        "mieszkaniowych,",
        "gruntowych niezabudowanych,",
        "gruntowych zabudowanych,",
        "komercyjnych (biurowych, handlowych, magazynowych),",
        "przemysłowych,",
        "specjalnych (np. użyteczności publicznej).",
      ],
    },
    {
      title: "Opracowania i ekspertyzy",
      list: [
        "analiza rynku nieruchomości z obszaru Górnego Śląska i\u00A0okolic,",
        "doradztwo w zakresie rynku nieruchomości,",
        "określenie wartości nieruchomości na potrzeby prywatne inwestora,",
        "ustalenie bankowo-hipotecznej wartości nieruchomości.",
      ],
    },
  ];

  const windows = services.map((window, windowIndex) => (
    <Window
      key={`serviceWindow${windowIndex}`}
      blueBar={false}
      title={window.title}
      list={window.list.map((service, index) => (
        <li key={`service${index}`}>{service}</li>
      ))}
    />
  ));

  return (
    <>
      <div id="uslugi" className="shifted-anchor"></div>
      <div>
        <SectionHeader text="Usługi" />
        <div className="section">
          <div className="windows">{windows}</div>
        </div>
      </div>
    </>
  );
}
