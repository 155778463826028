import { Banner } from "../Banner/Banner";
import { NotFoundBanner } from "../Banner/NotFoundBanner";
import { useWindowWidth } from "../../utils/useWindowWidth";
import background1920 from "../../images/wycena-nieruchomosci-sosnowiec-i-okolice1920.jpg";
import background2560 from "../../images/wycena-nieruchomosci-sosnowiec-i-okolice2560.jpg";
import backgroundMobile from "../../images/wycena-nieruchomosci-sosnowiec-i-okolice-mobile.jpg";
import backgroundTablet from "../../images/wycena-nieruchomosci-sosnowiec-i-okolice-tablet.jpg";
import "./Photo.css";

export function Photo({ defaultBanner = true }) {
  const  width  = useWindowWidth();
  return (
    <>
      <img
        className="photo"
        src={
          width > 2200
            ? background2560
            : width > 1050
            ? background1920
            : width > 600
            ? backgroundTablet
            : backgroundMobile
        }
        title="Sosnowiec - widok z Górki Środula"
        alt="Wycena nieruchomości - Górny Śląsk i okolice"
      ></img>
      {defaultBanner ? <Banner /> : <NotFoundBanner />}
    </>
  );
}
